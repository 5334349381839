import React from 'react'
import PhysicianLandingPage from 'containers/physician-landing-page'

const FrameFertilityLandingPage = () => (
  <PhysicianLandingPage
    physician="Frame Fertility"
    institution="Frame Fertility"
    referralCode="FRAMEFRIENDS"
  />
)

export default FrameFertilityLandingPage
